import React from 'react';

import "ol/ol.css";
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerImage from 'ol/layer/Image';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceImageWMS from 'ol/source/ImageWMS';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlSourceOSM from 'ol/source/OSM';
import OlLayerGroup from 'ol/layer/Group';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';


const mapStyle = ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: '0'
});

const style = (theme) => ({
    layerSwitcherStyle: {
        top: '10%',
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        zIndex: '1',
        height: 'auto'
    }
});

class SaliMap extends React.Component {

    constructor(props){
        super(props);

        this.mapRef = React.createRef();

        const dofSource = new OlSourceImageWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:DOF_SALI_DGU_KV',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        const multiSource = new OlSourceImageWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:Multi_sali',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        const termalSource = new OlSourceImageWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:Termal',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        const hillDMPSource = new OlSourceTileWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:HillDMP_Sali_DGU_KV',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        const hillDMRSource = new OlSourceTileWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:HillDMR_Sali_DGU',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        const dmrSource = new OlSourceTileWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:DMR_Sali_DGU_KV',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        const dmpSource = new OlSourceTileWMS({
            url: 'https://kartesali.prehnit.hr/geoserver/SALI/wms',
            params: {
                layers: 'SALI:DMP_Sali_DGU_KV',
                format: 'image/png'
            },
            serverType: 'geoserver',
            projection: 'EPSG:3857'
        });

        
        const dofLayer = new OlLayerImage({
            source: dofSource,
            visible: false
        });

        const termalLayer = new OlLayerImage({
            source: termalSource,
            visible: false
        });
        
        const multiLayer = new OlLayerImage({
            source: multiSource,
            visible: false
        });

        const hillDMPLayer = new OlLayerTile({
            source: hillDMPSource,
            visible: false
        });

        const hillDMRLayer = new OlLayerTile({
            source: hillDMRSource,
            visible: false
        });

        const dmrLayer = new OlLayerTile({
            source: dmrSource,
            visible: false
        });

        const dmpLayer = new OlLayerTile({
            source: dmpSource,
            visible: false
        });
        
        const wmsLayers = new OlLayerGroup({
            layers: [dofLayer, termalLayer, multiLayer, hillDMPLayer, hillDMRLayer, dmrLayer, dmpLayer]
        });

        const osmLayer = new OlLayerTile({
            source: new OlSourceOSM()
        });

        
        const map = new OlMap({
            view: new OlView({
                center: [1687720.99, 5456037.19],
                zoom: 18,
                projection: 'EPSG:3857',
                minZoom: 16,
                extent: [1685344.9342031572, 5452921.393788178, 1689744.2625634847, 5457328.513597388]
            }),
            layers: [osmLayer, wmsLayers],
        });

        map.on('click', (evt) => {
            this.setState({
                data: this.map.getCoordinateFromPixel(evt.pixel)
            });
        });
        

        this.state = {
            data: null,
            dofToggle: false,
            termalToggle: false,
            multiToggle: false,
            hillDMPToggle: false,
            hillDMRToggle: false,
            dmrToggle: false,
            dmpToggle: false,
            osmToggle: true,
        }
        this.map = map;
        this.dofLayer = dofLayer;
        this.multiLayer = multiLayer;
        this.termalLayer = termalLayer;
        this.hillDMPLayer = hillDMPLayer;
        this.hillDMRLayer = hillDMRLayer;
        this.dmrLayer = dmrLayer;
        this.dmpLayer = dmpLayer;
        this.osmLayer = osmLayer;
    }

    componentDidMount(){
        this.map.setTarget(this.mapRef.current);
    }

    handleCheckboxChange(layer){
        
        switch(layer){
            case "dof": this.clearAllLayers();
                this.setState({dofToggle: true}, this.dofLayer.setVisible(true));
                break;
            case "termal": this.clearAllLayers();
            this.setState({termalToggle: true}, this.termalLayer.setVisible(true));
                break;
            case "multi": this.clearAllLayers();
                this.setState({multiToggle: true}, this.multiLayer.setVisible(true));
                break;
            case "osm": this.clearAllLayers();
                this.setState({osmToggle: true}, this.osmLayer.setVisible(true));
                break;
            case "hillDMP": this.clearAllLayers();
                this.setState({hillDMPToggle: true}, this.hillDMPLayer.setVisible(true));
                break;
            case "hillDMR": this.clearAllLayers(); 
                this.setState({hillDMRToggle: true}, this.hillDMRLayer.setVisible(true));
                break;
            case "dmr": this.clearAllLayers(); 
                this.setState({dmrToggle: true}, this.dmrLayer.setVisible(true));
                break;
            case "dmp": this.clearAllLayers(); 
                this.setState({dmpToggle: true}, this.dmpLayer.setVisible(true));
                break;
        }
    }

    clearAllLayers(){
        this.osmLayer.setVisible(false);
        this.dofLayer.setVisible(false);
        this.multiLayer.setVisible(false);
        this.termalLayer.setVisible(false);
        this.hillDMPLayer.setVisible(false);
        this.hillDMRLayer.setVisible(false);
        this.dmpLayer.setVisible(false);
        this.dmrLayer.setVisible(false);
        this.setState({
            osmToggle: false,
            dofToggle: false,
            multiToggle: false,
            hillDMPToggle: false,
            hillDMRToggle: false,
            termalToggle: false,
            dmpToggle: false,
            dmrToggle: false
        });
    }

    clearData(){
        this.setState({
            data: null
        });
    }

    render(){
        const { data } = this.state;
        const { osmToggle, dofToggle, termalToggle, multiToggle, hillDMPToggle, hillDMRToggle, dmrToggle, dmpToggle} = this.state;
        const { classes } = this.props;

        console.log(this.dofLayer.getVisible());
        return (
            <Grid container direction = "column">
                    <Grid item>
                    <Card classes = {{root: classes.layerSwitcherStyle}}>
                        <CardContent>
                            <Grid container direction = "column">
                                <Grid item>
                                    <Typography variant = "h6">Slojevi</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {osmToggle}
                                            onChange = {() => this.handleCheckboxChange("osm")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="Open Street Map"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {dofToggle}
                                            onChange = {() => this.handleCheckboxChange("dof")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="Digitalni ortofoto"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {termalToggle}
                                            onChange = {() => this.handleCheckboxChange("termal")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="Termal"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {multiToggle}
                                            onChange = {() => this.handleCheckboxChange("multi")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="Multi"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {hillDMPToggle}
                                            onChange = {() => this.handleCheckboxChange("hillDMP")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="Hill DMP"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {hillDMRToggle}
                                            onChange = {() => this.handleCheckboxChange("hillDMR")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="Hill DMR"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {dmrToggle}
                                            onChange = {() => this.handleCheckboxChange("dmr")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="DMR"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked = {dmpToggle}
                                            onChange = {() => this.handleCheckboxChange("dmp")}
                                            name="checkedF"
                                            indeterminate
                                        />
                                        }
                                        label="DMP"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                        <div ref = {this.mapRef} style = {mapStyle}/>
                    </Grid>              
            </Grid>
        );
    }
}

export default withStyles(style)(SaliMap);
