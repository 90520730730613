import React from 'react';

import './App.css';
import SaliMap from './SaliMap.jsx';

function App() {
  return (
    <div id = "map">
      <SaliMap/>
    </div>
  );
}

export default App;
